<template>
  <div class="svg_holder" :class="[color, size]">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512.7 513.25"><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path d="M509.23,482.85c-22.46-22.56-44.86-45.18-67.45-67.6a36.46,36.46,0,0,1-8.87-14.09c-15.4-42.67-42.5-75.75-81.89-98.48-6.7-3.87-13.72-7.18-20.93-10.93,33.83-25.31,54.63-57.74,57.24-100.15s-12.15-78-43.42-106.72c-55.32-50.76-154.16-45.51-201.3,32.85L26.5,0,0,26.43a43.71,43.71,0,0,1,4,3.18Q64.47,90,124.94,150.42c1.14,1.13,3.12,3,2.82,3.7-1.33,3.18,1.07,4.24,2.65,5.68,12.43,11.34,25.39,22.15,37.29,34,28,27.92,55.22,56.52,83.12,84.49,15.2,15.23,31.43,29.43,46.67,44.63Q390.42,415.63,483,508.67c1.39,1.39,2.17,3.37,2.92,4.58l26.82-26.78C511.72,485.44,510.49,484.12,509.23,482.85ZM297.63,265.68c-2.25,1.17-3.81,1.69-6-.53q-60.27-60.57-120.74-120.94a11.44,11.44,0,0,1-.67-1.09c17.72-35.23,45.92-54.72,85.55-55.63,52.22-1.21,95.46,42.38,94.3,94.9C349.21,220.32,331.32,248.11,297.63,265.68Z"/><path d="M271.18,350c-.82-.8-2.53-1-3.83-.95-5.8,0-11.67.85-17.4.27-16.68-1.67-32.7-15.7-36-31.18l19.36-5L129.48,207.45l-1.26.53C133,219.81,137,232,142.65,243.4c9.54,19.07,24,34.21,42.54,46.83-35.86,14.2-63.54,37.1-84.24,68.3S69.28,424.18,69.22,462H106.4c1.41-55.11,26-96.8,71.68-127.37,10.38,23.22,27,39.41,50.43,47.64s46.56,7.08,69.08-6C288.41,367.06,279.87,358.44,271.18,350Z"/></g></g></svg>
  </div>
</template>

<script>
export default {
  name: 'IconNoUser2',
  props: ['size', 'color']
}
</script>

<style scoped>
  .svg_holder {
    display: -webkit-flex; /* Safari */
    -webkit-align-items: center; /* Safari 7.0+ */
    display: flex;
    align-items: center;
    -webkit-justify-content:center;
    justify-content:center;
  }
</style>
